<template>
  <div v-if="yieldSettingsPlayground" class="columns">
    <div class="column">
      <playground-settings @showResults="showResults" :maxSeats="maxSeats" />

      <div class="is-divider"></div>

      <playground-table
        :showAveragePrice="!yieldSettingsPlayground.ApplyLocationUtilization"
        :playground="playground"
      />
    </div>
    <div class="column is-one-third">
      <div class="has-text-right">
        <a @click="startBlank" class="is-size-7">
          <span class="icon is-small">
            <font-awesome-icon :icon="['fas', 'file']" aria-hidden="true" />
          </span>
          <span>Start blank</span>
        </a>

        <a @click="resetValues" class="is-size-7 has-margin-left">
          <span class="icon is-small">
            <font-awesome-icon :icon="['fas', 'undo']" aria-hidden="true" />
          </span>
          <span>Reset values</span>
        </a>

        <a
          @click="showAllSettings = !showAllSettings"
          class="is-size-7 has-margin-left"
        >
          <span v-if="showAllSettings" class="icon is-small">
            <font-awesome-icon
              :icon="['fas', 'chevron-up']"
              aria-hidden="true"
            />
          </span>
          <span v-if="showAllSettings">Close all</span>

          <span v-if="!showAllSettings" class="icon is-small">
            <font-awesome-icon
              :icon="['fas', 'chevron-down']"
              aria-hidden="true"
            />
          </span>
          <span v-if="!showAllSettings">Expand all</span>
        </a>
      </div>

      <!-- PRICES -->
      <prices
        :showBox="showAllSettings"
        @yieldSettingsUpdated="yieldSettingsUpdated"
      />

      <!-- LOCATION UTILIZATION SETTINGS -->
      <location-utilization
        :showBox="showAllSettings"
        @yieldSettingsUpdated="yieldSettingsUpdated"
      />

      <!-- SPACE UTILIZATION SETTINGS -->
      <space-utilization
        v-if="yieldSettingsPlayground.MeetingtypeId === 1"
        :showBox="showAllSettings"
        @yieldSettingsUpdated="yieldSettingsUpdated"
      />

      <!-- DAY PERCENTAGES SETTINGS -->
      <day-percentage
        :showBox="showAllSettings"
        @yieldSettingsUpdated="yieldSettingsUpdated"
      />

      <!-- GROUP SIZE SETTINGS -->
      <group-size
        v-if="yieldSettingsPlayground.MeetingtypeId === 1"
        :showBox="showAllSettings"
        @yieldSettingsUpdated="yieldSettingsUpdated"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import yieldProvider from '@/providers/yield'

const PlaygroundSettings = () =>
  import('@/components/Yield/Playground/PlaygroundSettings')
const PlaygroundTable = () =>
  import('@/components/Yield/Playground/PlaygroundTable')
const YieldSettingsPrices = () =>
  import('@/components/Yield/Playground/YieldSettingsPrices')
const YieldSettingsDayPercentage = () =>
  import('@/components/Yield/Playground/YieldSettingsDayPercentage')
const YieldSettingsGroupSize = () =>
  import('@/components/Yield/Playground/YieldSettingsGroupSize')
const YieldSettingsLocationUtilization = () =>
  import('@/components/Yield/Playground/YieldSettingsLocationUtilization')
const YieldSettingsSpaceUtilization = () =>
  import('@/components/Yield/Playground/YieldSettingsSpaceUtilization')

export default {
  components: {
    'playground-settings': PlaygroundSettings,
    'playground-table': PlaygroundTable,
    prices: YieldSettingsPrices,
    'day-percentage': YieldSettingsDayPercentage,
    'group-size': YieldSettingsGroupSize,
    'location-utilization': YieldSettingsLocationUtilization,
    'space-utilization': YieldSettingsSpaceUtilization,
  },

  props: {
    activeIndex: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      endMinutes: 1020,
      formatter: (v) => `${v - 100}%`,
      locationId: Number(this.$route.params.locationId),
      locationUtilizationValues: [],
      nrOfDayParts: 8,
      minSeats: 1,
      maxSeats: 1,
      percentages: [0, 25, 50, 75, 100],
      playground: null,
      selectedSpace: null,
      showAllSettings: true,
      spaceConfiguration: { MinSeats: 1, MaxSeats: 20 },
      spaceUtilizationValues: [],
      startMinutes: 540,
      timeSlots: [],
      yieldSettingsOriginal: null,
    }
  },

  computed: {
    ...mapState('yieldStore', ['yieldSettingsPlayground']),
  },

  watch: {
    activeIndex: {
      immediate: true,
      handler: function(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.buildYieldPlayground()
        }
      },
    },
  },

  created() {
    this.yieldSettingsOriginal = JSON.parse(
      JSON.stringify(this.yieldSettingsPlayground)
    )

    if (this.yieldSettingsPlayground.MeetingtypeId === 1) {
      this.maxSeats = 20
    }

    this.buildYieldPlayground()
  },

  beforeDestroy() {
    this.setYieldSettingsPlayground(null)
  },

  methods: {
    ...mapMutations('yieldStore', ['setYieldSettingsPlayground']),

    yieldSettingsUpdated() {
      this.buildYieldPlayground()
      this.$emit('yieldSettingsUpdated', this.yieldSettingsPlayground)
    },

    showResults(settings) {
      this.percentages = settings.percentages
      this.minSeats = settings.minSeats
      this.maxSeats = settings.maxSeats
      this.nrOfDayParts = settings.nrOfDayParts
      this.buildYieldPlayground()
    },

    buildYieldPlayground() {
      yieldProvider.methods
        .builYieldPlayground(
          this.locationId,
          this.minSeats,
          this.maxSeats,
          this.nrOfDayParts,
          this.yieldSettingsPlayground,
          this.percentages
        )
        .then((response) => {
          if (response.status === 200) {
            this.playground = response.data
          }
        })
    },

    resetValues() {
      this.setYieldSettingsPlayground(this.yieldSettingsOriginal)
      this.$emit('yieldSettingsUpdated', this.yieldSettingsPlayground)

      this.buildYieldPlayground()
    },

    startBlank() {
      let dayPercentages = this.yieldSettingsPlayground.DayPercentages
      for (let i = 0; i < dayPercentages.length; i++) {
        dayPercentages[i].Percentage = 0
      }

      let ys = JSON.parse(JSON.stringify(this.yieldSettingsPlayground))
      ys.ApplyDayPercentage = false
      ys.ApplyGroupSize = false
      ys.ApplyLocationUtilization = false
      ys.ApplySpacePercentage = false
      ys.ApplySpaceUtilization = false
      ys.DayPercentages = dayPercentages
      ys.GroupSizes = []
      ys.LocationUtilizationBottomValue = 1
      ys.LocationUtilizationCalculationType = 0
      ys.LocationUtilizationDefaultHours = 8
      ys.LocationUtilizationTopValue = 1
      ys.PriceParts = []
      ys.SpaceUtilizationBottomValue = 1
      ys.SpaceUtilizationTopValue = 1

      this.setYieldSettingsPlayground(ys)
      this.$emit('yieldSettingsUpdated', ys)

      this.buildYieldPlayground()
    },
  },
}
</script>

<style></style>
